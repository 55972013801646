exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".masonry__grid--2qySY {\n  width: 100%;\n  margin: 0 auto;\n}\n\n.masonry__gridGutter--2fOti {\n  width: 100%;\n}\n\n.masonry__gridSizer--h6EPu {\n  width: 100%;\n}\n\n.masonry__gridItem--3gUaq {\n  width: 100%;\n  margin-bottom: 10px;\n}\n\n.masonry__gridItem2--3x0I2 {\n  width: 100%;\n}\n\n.masonry__gridItemFullWidth--zYzUe {\n  width: 100%;\n}\n\n@media (min-width: 768px) {\n  .masonry__grid--2qySY {\n    width: 658px;\n    margin: 0 auto;\n  }\n  .masonry__gridGutter--2fOti {\n    width: 26px;\n  }\n  .masonry__gridSizer--h6EPu {\n    width: 316px;\n  }\n  .masonry__gridItem--3gUaq {\n    width: 316px;\n    margin-bottom: 30px;\n  }\n  .masonry__gridItem2--3x0I2 {\n    width: 658px;\n  }\n  .masonry__gridItemFullWidth--zYzUe {\n    width: 100%;\n  }\n  .masonry__gridItemHeadlines--1uOiD {\n    width: 658px;\n  }\n}\n@media (min-width: 1024px) {\n  .masonry__grid--2qySY {\n    width: 1000px;\n  }\n  .masonry__gridItem2--3x0I2 {\n    width: 658px;\n  }\n  .masonry__gridItemFullWidth--zYzUe {\n    width: 1000px;\n  }\n  .masonry__gridItemHeadlines--1uOiD {\n    width: 316px;\n  }\n}", ""]);

// exports
exports.locals = {
	"grid": "masonry__grid--2qySY",
	"gridGutter": "masonry__gridGutter--2fOti",
	"gridSizer": "masonry__gridSizer--h6EPu",
	"gridItem": "masonry__gridItem--3gUaq",
	"gridItem2": "masonry__gridItem2--3x0I2",
	"gridItemFullWidth": "masonry__gridItemFullWidth--zYzUe",
	"gridItemHeadlines": "masonry__gridItemHeadlines--1uOiD"
}; 
var style = module.exports.toString();module.exports = exports.locals || { stylesheet: "" };if (!module.exports.stylesheet) { module.exports.stylesheet = style; };